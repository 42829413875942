import Vue from 'vue'
import VueRouter from 'vue-router'

//import hostList from '../data/urls.json';
Vue.use(VueRouter);

// //console.log(location)
// if (location.pathname == "/") {
// 	hostList.forEach(el => {
// 		if (el.host == location.host) {
// 			location.href = el.redirect;
// 			return;
// 		}
// 	});
// }

const routes = [
	{
		path: '/:EventUrl/:VenueUrl/login',
		name: 'Login',
		props: true,
		component: () => import('../views/login.vue')
	},
	{
		path: '/:EventUrl/:VenueUrl',
		name: 'App',
		props: true,
		component: () => import('../views/slayout.vue'),
		children: [
			{
				path: 'profile',
				name: 'Profile',
				component: () => import('../views/profile.vue')
			},
		]
	},
	{
		path: '/:EventUrl/:VenueUrl',
		name: 'ProfileApp',
		props: true,
		component: () => import('../views/layout.vue'),
		children: [
			{
				path: 'app',
				name: 'Dashboard',
				component: () => import('../views/application.vue')
			},
			{
				path: 'thankyou',
				name: 'ThankYou',
				component: () => import('../views/thankyou.vue')
			},
		]
	},
	
	{
		path: '*',
		name: 'NoFound',
		component: () => import('../views/notfound.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
