import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
// import SecureLS from 'secure-ls';
// var ls = new SecureLS({ encodingType: 'aes' });

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState()
		// {
		// 	storage: {
		// 		getItem: (key) => ls.get(key),
		// 		setItem: (key, value) => ls.set(key, value),
		// 		removeItem: (key) => ls.remove(key)
		// 	}
		// })
	],
	state: {
		pageTitle: '',
		isHeadShotOpen: false,
		imgHeadShot: '',
		isIdOpen: false,
		imgId: '',
		isIdBackOpen: false,
		imgIdBack: '',
		userType: '',
		eventUrl:'',
		venueUrl:'',
		eventInfo: {
			EventId: '',
			EventName: '',
			EventUrl: '',
			AppId: '',
			AppIdDisplay:'',
			UserName: '',
			Email:'',
			TemplateID:0
		},		
		newAppId:'',
	},
	mutations: {
		resetApplication(state){			
			state.imgHeadShot = '';
			state.imgId = '';
			state.imgIdBack = '';
		},
		setEventInfo(state, payload) {
			state.eventInfo.EventId = payload.event_id;
			state.eventInfo.EventUrl = payload.event_url;
			state.eventInfo.EventName = payload.event_name;
			state.eventInfo.AppId = payload.application_id;
			state.eventInfo.AppIdDisplay = payload.app_id;
			state.eventInfo.UserName = payload.firstName;
			state.eventInfo.Email = payload.email;
			state.eventInfo.TemplateID = payload.template_id;			
			state.userType = "Media";
			sessionStorage.setItem("token", payload.token);
		},
		setPageTitle(state, payload) {
			state.pageTitle = payload;
		},		
		setHeadshot(state, payload) {
			state.isHeadShotOpen = payload;
		},
		setHeadshotImg(state, payload) {
			state.imgHeadShot = payload;
		},
		setIdOpen(state, payload) {
			state.isIdOpen = payload;
		},
		setIdImg(state, payload) {
			state.imgId = payload;
		},
		setIdBackOpen(state, payload) {
			state.isIdBackOpen = payload;
		},
		setIdBackImg(state, payload) {
			state.imgIdBack = payload;
		},
		setEventVenueUrl(state, payload) {
			state.eventUrl = payload.e;
			state.venueUrl = payload.v;
		},
		// setVenueUrl(state, payload) {
		// 	state.venueUrl = payload;
		// },
		setNewAppId(state, payload) {
			state.newAppId = payload;
		},
		logout(state) {
			state.eventInfo.EventId = "";
			state.eventInfo.EventName = "";
			state.eventInfo.AppId = "";
			state.eventInfo.UserName = "";
			state.eventInfo.Email = "";
			state.UserType = "";
		},
	},
	actions: {},
	modules: {}
});
