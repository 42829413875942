import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

Vue.config.productionTip = false



//--Start For Live Server
Vue.prototype.$apiPhotoPath='https://accred.api.dnanetworks.in';
Vue.prototype.$apiPathNew='https://accred.api.dnanetworks.in';

//Vue.prototype.$apiPathNew = 'https://localhost:44319/';
//--End

Vue.prototype.$aKey='ticketgenieapikey';


//--Start For Local
// const axiosInstance = axios.create({
  // baseURL: 'https://pklapi.startv.com',
//   headers: { Appkey: 'ticketgenieapikey' }
// })
//--End

//--Start For Live Server
//  const axiosInstance = axios.create({
//    baseURL: 'https://accred.api.dnanetworks.in',
//    headers: { Appkey: 'ticketgenieapikey' }
//  })
//--End
var axiosInstance = axios.create({
  baseURL: 'https://accred.api.dnanetworks.in',
 //baseURL: 'https://localhost:44319/',
 //baseURLTEST: 'https://localhost:44319/',
  headers: {    
    Authorization: 'Bearer ' + sessionStorage.getItem("token"),
    Appkey: 'ticketgenieapikey',
  }
})


var axiosInstancetry = axios.create({
 // baseURL: 'https://accred.api.dnanetworks.in',
 //baseURL: 'https://localhost:44319/',
 baseURL: 'https://localhost:44319/',
  headers: {    
    Authorization: 'Bearer ' + sessionStorage.getItem("token"),
    Appkey: 'ticketgenieapikey',
  }
})



Vue.prototype.$axios = axiosInstance
Vue.prototype.$axios1 = axiosInstancetry


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
